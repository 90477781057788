import React from 'react';
import { Layout } from 'antd';

import backgroundImage from './components/public/globe-buildings.png';
import PageHeader from './components/public/Header';
import LeftNav from './components/public/LeftNav';
import PageFooter from './components/public/PageFooter';
import RequestPasswordReset from './components/public/RequestPasswordReset';

const { Header, Footer, Sider, Content } = Layout;

const layoutStyle = {
  height: '100vh',
};
const headerStyle = {
  textAlign: 'center',
  color: '#000000',
  height: 90,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#ffffff',
  borderBottomColor: '#CCCCCC',
  borderBottomStyle: 'solid',
};
const contentStyle = {
  textAlign: 'center',
  height: '100%',
  minHeight: 120,
  color: '#000000',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#ffffff',
  paddingTop: '20px',
};
const siderStyle = {
  height: '100vh',
  textAlign: 'center',
  lineHeight: '120px',
  color: '#000000',
  borderRightColor: '#CCCCCC',
  borderRightStyle: 'solid',
  backgroundColor: '#ffffff',
};
const footerStyle = {
  textAlign: 'center',
  color: '#000',
  backgroundColor: '#fff',
  borderTopColor: '#CCCCCC',
  borderTopStyle: 'solid',
};

function RemoteAccessServicesRoot() {
  return (
    <Layout style={layoutStyle}>
      <Sider style={siderStyle} width={210}>
        <LeftNav />
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <PageHeader />
        </Header>
        <Content style={contentStyle}>
          <RequestPasswordReset />
        </Content>
        <Footer style={footerStyle}>
          <PageFooter />
        </Footer>
      </Layout>
    </Layout>
  );
}

export default RemoteAccessServicesRoot;
