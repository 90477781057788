import React, { ReactNode, useEffect, useState } from 'react';
import { message } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import {
  DeviceType,
  DeviceTypeInput,
} from '@totem/components/deviceTypes/types';
import MergeDialog from '@totem/components/vendors/MergeDialog';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};
const DeviceTypeContainer = ({ children }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DeviceType[]>([]);
  const [rawData, setRawData] = useState<DeviceType[]>([]);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<DeviceType>(null);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [input, updateInput] = useState<DeviceTypeInput>({ searchName: '' });

  const filterData = (chkData: DeviceType[]) => {
    if (isNotNull(chkData)) {
      let filtered: DeviceType[] = [...chkData];
      let clearSelections = false;

      if (isNotNull(input.searchName) && input.searchName !== '') {
        filtered = filtered.filter((chk) =>
          chk.name.toLowerCase().startsWith(input.searchName.toLowerCase()),
        );
        clearSelections = true;
      }

      if (clearSelections) {
        setSelectedDeviceTypes([]);
      }

      setData(filtered);
    } else {
      setData([]);
    }
  };

  const setDeviceTypeSelected = (deviceType: DeviceType, selected: boolean) => {
    if (selected) {
      if (
        selectedDeviceTypes.findIndex((chk) => chk.name === deviceType.name) <=
        -1
      ) {
        setSelectedDeviceTypes([...selectedDeviceTypes, deviceType]);
      }
    } else {
      const filtered = selectedDeviceTypes.filter(
        (chk) => chk.name !== deviceType.name,
      );
      setSelectedDeviceTypes(filtered);
    }
  };

  const setInput = (updated: Partial<DeviceTypeInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    //setRefreshData(true);
  };

  useEffect(() => {
    filterData(rawData);
  }, [input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/deviceTypes`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceType[]) => {
          setRawData(result);
          filterData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string, item: DeviceType) => {
    switch (newAction) {
      case 'merge':
        if (selectedDeviceTypes.length > 1) {
          setAction(newAction);
          setActionItem(item);
        } else {
          messageApi.error('Select two or more vendors before merging!');
        }
        break;
      case 'clear_vendor_selections':
        setSelectedDeviceTypes([]);
        break;
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      default:
        setAction(newAction);
        setActionItem(item);
    }
  };

  return (
    <DeviceTypeContext.Provider
      value={{
        loading: isLoading,
        data,
        rawData,
        action,
        actionItem,
        onAction: handleAction,
        input,
        setInput,
        selectedDeviceTypes,
        setSelectedDeviceType: setDeviceTypeSelected,
      }}
    >
      {contextHolder}
      <div>{children}</div>
      {action === 'merge' && <MergeDialog />}
    </DeviceTypeContext.Provider>
  );
};

export default DeviceTypeContainer;
