import React from 'react';

const headerStyle = {
  width: '100%',
  repeat: true,
  fontSize: '30px',
  fontWeight: 700,
};
const PageHeader = () => {
  return <div style={headerStyle}>Remote Access Services</div>;
};

export default PageHeader;
