import React, { useContext } from 'react';
import { MergeCellsOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import { isNull } from '@totem/utilities/common';

export interface Props {
  isPrimary?: boolean;
}
const MergeButton = ({ isPrimary }: Props) => {
  const { selectedDeviceTypes, action, onAction } =
    useContext(DeviceTypeContext);

  return (
    <Button
      icon={<MergeCellsOutlined />}
      type={
        typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
      }
      disabled={
        action === 'merge' ||
        isNull(selectedDeviceTypes) ||
        selectedDeviceTypes.length < 2
      }
      onClick={() => onAction('merge', null)}
    >
      Merge
    </Button>
  );
};

export default MergeButton;
