import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';

import ReferencesModal, {
  EMPTY_ID,
} from '@totem/components/common/referencesModal/ReferencesModal';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import {
  addDeviceFilters,
  buildDeviceFilters,
} from '@totem/components/devices/devicesContainer/utilities';
import { Params, Reference } from '@totem/types/common';
import {
  Device,
  DeviceFilterOptions,
  DeviceFilters,
  DeviceQueryResult,
  DeviceQueryResults,
  DevicesConnectionInput,
} from '@totem/types/devices';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  DEVICES_ENDPOINT,
  DEVICES_PAGINATION_ENDPOINT,
} from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

import '../devices.css';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  deviceEndPoint?: string;
  deviceFiltersEndPoint?: string;
  defaultFilters?: DeviceFilters;
  staticFilters?: DeviceFilters;
};

const DeviceEmbeddedContainer = ({
  refresh,
  onRecordTotalChanged,
  onDataRefreshRequested,
  children,
  deviceEndPoint,
  deviceFiltersEndPoint,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [input, updateInput] = useState<DevicesConnectionInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'device.displayName',
    sortDirection: '1',
    organizationId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.organizationId !== 'undefined' &&
      defaultFilters.organizationId !== null
        ? defaultFilters.organizationId
        : [],
    regionId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.regionId !== 'undefined' &&
      defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    buildingId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.buildingId !== 'undefined' &&
      defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    controlSystemId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.controlSystemId !== 'undefined' &&
      defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
    operatingSystem:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.operatingSystem !== 'undefined' &&
      defaultFilters.operatingSystem !== null
        ? defaultFilters.operatingSystem
        : [],
    type:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.type !== 'undefined' &&
      defaultFilters.type !== null
        ? defaultFilters.type
        : [],
    vendor:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.vendor !== 'undefined' &&
      defaultFilters.vendor !== null
        ? defaultFilters.vendor
        : [],
    model:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.model !== 'undefined' &&
      defaultFilters.model !== null
        ? defaultFilters.model
        : [],
    searchTerm:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.searchTerm !== 'undefined' &&
      defaultFilters.searchTerm !== null
        ? defaultFilters.searchTerm
        : '',
    searchIpAddress:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.searchIpAddress !== 'undefined' &&
      defaultFilters.searchIpAddress !== null
        ? defaultFilters.searchIpAddress
        : '',
    isManaged:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.isManaged !== 'undefined' &&
      defaultFilters.isManaged !== null
        ? defaultFilters.isManaged
        : [],
    isMonitored:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.isMonitored !== 'undefined' &&
      defaultFilters.isMonitored !== null
        ? defaultFilters.isMonitored
        : [],
  });

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSendingUpdate, setIsSendingUpdate] = useState(false);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] =
    useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<DeviceFilterOptions>({
    type: [],
    vendor: [],
    models: [],
    operatingSystem: [],
    region: [],
    building: [],
  });
  const [deviceData, setDeviceData] = useState<DeviceQueryResults>(null);
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceQueryResult>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const setInput = (updated: Partial<DevicesConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    setRefreshData(true);
  }, [deviceEndPoint]);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildDeviceFilters(input),
    };

    params.filters = addDeviceFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      const deviceFiltersUrl =
        typeof deviceFiltersEndPoint !== 'undefined' &&
        deviceFiltersEndPoint !== null
          ? deviceFiltersEndPoint
          : `${DEVICES_ENDPOINT}/filters`;

      fetch(`${deviceFiltersUrl}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      const deviceUrl =
        typeof deviceEndPoint !== 'undefined' && deviceEndPoint !== null
          ? deviceEndPoint
          : DEVICES_PAGINATION_ENDPOINT;

      fetch(`${deviceUrl}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: DeviceQueryResults) => {
          setDeviceData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  useEffect(() => {
    if (isDeleting === true && selectedDevice !== null) {
      setIsDeleting(false);
      fetch(`${DEVICES_ENDPOINT}/${selectedDevice.device.id}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        if (res.status < 400) {
          setIsDeleting(false);
          setSelectedDevice(null);
          setRefreshData(true);
        } else {
          notification.error({
            message: 'Error removing device!',
            description: 'An error occurred while removing the device.',
            duration: 0,
          });
          setIsDeleting(false);
          setSelectedDevice(null);
        }
      });
    }
  }, [isDeleting, selectedDevice]);

  const getDeviceReferences = () => {
    let ref: Reference = {
      organizationId: EMPTY_ID,
      regionId: EMPTY_ID,
      buildingId: EMPTY_ID,
      controlSystemId: EMPTY_ID,
    };

    if (typeof selectedDevice !== 'undefined' && selectedDevice !== null) {
      ref = {
        organizationId: selectedDevice.device.organizationId,
        regionId: selectedDevice.device.regionId,
        buildingId: selectedDevice.device.buildingId,
        controlSystemId: selectedDevice.device.controlSystemId,
      };
    }
    return ref;
  };

  const sendDeviceReferenceUpdate = useCallback(
    async (device: Device) => {
      if (isSendingUpdate) {
        return;
      }

      setIsSendingUpdate(true);

      fetch(`${DEVICES_ENDPOINT}/references`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(device),
      }).then(() => {
        setIsSendingUpdate(false);
        setShowReferenceModal(false);
        setRefreshData(true);
      });
    },
    [isSendingUpdate],
  );

  const handleSubmitReferences = (reference: Reference) => {
    const deviceUpdate = {
      ...selectedDevice.device,
      organizationId: reference.organizationId,
      regionId: reference.regionId,
      buildingId: reference.buildingId,
      controlSystemId: reference.controlSystemId,
    };
    // eslint-disable-next-line no-shadow
    setSelectedDevice(null);
    sendDeviceReferenceUpdate(deviceUpdate);
  };

  const handleAction = (action: string, device: DeviceQueryResult) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'ShowReferenceModal':
        setSelectedDevice(device);
        setShowReferenceModal(true);
        break;
      case 'RemoveDevice':
        setSelectedDevice(device);
        setIsDeleting(true);
        break;
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        deviceData,
        filterOptions,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {showReferenceModal && selectedDevice !== null && (
        <ReferencesModal
          title={`Assign: ${selectedDevice.device.displayName}`}
          isVisible={showReferenceModal}
          references={getDeviceReferences()}
          onSubmit={handleSubmitReferences}
          onClose={() => {
            setShowReferenceModal(false);
            setSelectedDevice(null);
          }}
          showBuilding
          showControlSystem
          showOrganization={false}
          showRegion
        />
      )}
    </DeviceContext.Provider>
  );
};

export default DeviceEmbeddedContainer;
