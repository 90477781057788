import React from 'react';

import logo from './IB_Logo.png';

const logoStyle = {
  width: 200,
};
const LeftNav = () => {
  return <img alt="Logo" src={logo} style={logoStyle} />;
};

export default LeftNav;
