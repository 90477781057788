import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import AssetInventorySummary from '@totem/components/assetInventory/AssetInventorySummary';
import AssetInventoryTable from '@totem/components/assetInventory/AssetInventoryTable';
import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DeviceExport from '@totem/components/devices/devicesContainer/DeviceExport';
import DeviceFilters from '@totem/components/devices/devicesContainer/DeviceFilters';
import DeviceSearch from '@totem/components/devices/devicesContainer/DeviceSearch';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const AssetInventoryPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) === 3;

  const tabItems: TabsProps['items'] = [
    {
      key: 'summary',
      label: <TabTitle>Summary</TabTitle>,
      children: <AssetInventorySummary />,
    },
    {
      key: 'details',
      label: <TabTitle>Details</TabTitle>,
      children: (
        <>
          <DeviceSearch />
          <br />
          <AssetInventoryTable />
        </>
      ),
    },
  ];

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DeviceContainer
            onRecordTotalChanged={setTotalRecords}
            defaultFilters={{ isMonitored: ['ACTIVE'] }}
          >
            <DeviceFilters title="Asset Inventory" />
            <Tabs
              defaultActiveKey="Report"
              tabBarExtraContent={
                <span>
                  <RecordCountBadge count={totalRecords} />
                  {isSysAdmin && (
                    <span>
                      &nbsp;&nbsp;&nbsp;
                      <DeviceExport />
                    </span>
                  )}
                </span>
              }
              style={styles.tabContainer}
              items={tabItems}
            />
          </DeviceContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default AssetInventoryPage;
