import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { DevicesConnectionInput } from '@totem/types/devices';

import '../devices.css';
const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const DevicesFilterChips = () => {
  const { input, setInput, filterOptions } = useContext(DeviceContext);
  const handleMultiFilterDelete = (
    property: keyof DevicesConnectionInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const GetOptionNameFromValue = (option: string, value: string) => {
    switch (option) {
      case 'Building':
        for (let idx = 0; idx < filterOptions.building.length; idx++) {
          if (filterOptions.building[idx].value === value) {
            return filterOptions.building[idx].name;
          }
        }
        break;
      case 'Region':
        for (let idx = 0; idx < filterOptions.region.length; idx++) {
          if (filterOptions.region[idx].value === value) {
            return filterOptions.region[idx].name;
          }
        }
        break;
      default:
        return '';
    }
    return '';
  };

  return (
    <>
      {input.vendor &&
        input.vendor.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Vendor: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('vendor', option)}
            />
          );
        })}
      {input.type &&
        input.type.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Type: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('type', option)}
            />
          );
        })}
      {input.operatingSystem &&
        input.operatingSystem.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`OS: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('operatingSystem', option)}
            />
          );
        })}
      {input.regionId &&
        input.regionId.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Device: ${GetOptionNameFromValue('Region', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('regionId', option)}
            />
          );
        })}
      {input.buildingId &&
        input.buildingId.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Building: ${GetOptionNameFromValue('Building', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          );
        })}
    </>
  );
};

export default DevicesFilterChips;
