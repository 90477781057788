import { Filter } from '@totem/types/common';
import { DeviceFilters, DevicesConnectionInput } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

export const sortStringAscending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortStringDescending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortNumberAscending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortNumberDescending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const buildDeviceFilters = (input: DevicesConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
    paramFilters.push({
      field: 'displayName',
      operator: 'cscontains',
      values: [input.searchTerm],
    });
  }

  if (isNotNull(input.searchIpAddress) && input.searchIpAddress !== '') {
    paramFilters.push({
      field: 'ipAddresses',
      operator: 'cscontains',
      values: [input.searchIpAddress],
    });
  }

  if (isNotNull(input.isManaged) && input.isManaged.length > 0) {
    paramFilters.push({
      field: 'flags.managed.status',
      values: input.isManaged,
    });
  }

  if (isNotNull(input.isMonitored) && input.isMonitored.length > 0) {
    paramFilters.push({
      field: 'flags.monitored.status',
      values: input.isMonitored,
    });
  }

  if (isNotNull(input.organizationId) && input.organizationId.length > 0) {
    paramFilters.push({
      field: 'organizationId',
      values: input.organizationId,
    });
  }
  if (isNotNull(input.regionId) && input.regionId.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (isNotNull(input.type) && input.type.length > 0) {
    paramFilters.push({
      field: 'type',
      values: input.type,
    });
  }
  if (isNotNull(input.vendor) && input.vendor.length > 0) {
    paramFilters.push({
      field: 'vendor',
      values: input.vendor,
    });
  }
  if (isNotNull(input.operatingSystem) && input.operatingSystem.length > 0) {
    paramFilters.push({
      field: 'operatingSystem',
      values: input.operatingSystem,
    });
  }
  if (isNotNull(input.model) && input.model.length > 0) {
    paramFilters.push({
      field: 'attribute:Model',
      values: input.model,
    });
  }

  return paramFilters;
};

export const addDeviceFilters = (
  currentFilters: Filter[],
  input: DeviceFilters,
) => {
  if (isNotNull(input)) {
    if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
      currentFilters.push({
        field: 'displayName',
        operator: 'cscontains',
        values: [input.searchTerm],
      });
    }

    if (isNotNull(input.searchIpAddress) && input.searchIpAddress !== '') {
      currentFilters.push({
        field: 'ipAddresses',
        operator: 'cscontains',
        values: [input.searchIpAddress],
      });
    }

    if (isNotNull(input.isManaged) && input.isManaged.length > 0) {
      currentFilters.push({
        field: 'device.flags.managed.status',
        values: input.isManaged,
      });
    }

    if (isNotNull(input.isMonitored) && input.isMonitored.length > 0) {
      currentFilters.push({
        field: 'device.flags.monitored.status',
        values: input.isMonitored,
      });
    }

    if (isNotNull(input.organizationId) && input.organizationId.length > 0) {
      currentFilters.push({
        field: 'organizationId',
        values: input.organizationId,
      });
    }
    if (isNotNull(input.regionId) && input.regionId.length > 0) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (isNotNull(input.vendor) && input.vendor.length > 0) {
      currentFilters.push({
        field: 'vendor',
        values: input.vendor,
      });
    }
    if (isNotNull(input.type) && input.type.length > 0) {
      currentFilters.push({
        field: 'type',
        values: input.type,
      });
    }
    if (isNotNull(input.operatingSystem) && input.operatingSystem.length > 0) {
      currentFilters.push({
        field: 'operatingSystem',
        values: input.operatingSystem,
      });
    }
    if (isNotNull(input.model) && input.model.length > 0) {
      currentFilters.push({
        field: 'attribute:Model',
        values: input.model,
      });
    }
  }

  return currentFilters;
};
