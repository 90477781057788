import React, { useContext, useState } from 'react';
import { Col, Row } from 'antd';

import ChartHorizontalBarSummary from '@totem/components/assetInventory/ChartHorizontalBarSummary';
import ChartModal from '@totem/components/assetInventory/ChartModal';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';

const ChartLayout = () => {
  const { input, setInput } = useContext(DeviceContext);
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [showDetailedChart, setShowDetailedChart] = useState<boolean>(false);

  const handleChartClicked = (field: string, label: string) => {
    setSelectedField(field);
    setSelectedLabel(label);
    setShowDetailedChart(true);
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="type"
            centerLabel="Device Type (Top 10)"
            onItemClicked={(item) => setInput({ ...input, type: [item.x] })}
            onClick={handleChartClicked}
          />
        </Col>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="vendor"
            centerLabel="Vendor (Top 10)"
            onItemClicked={(item) => setInput({ ...input, vendor: [item.x] })}
            onClick={handleChartClicked}
          />
        </Col>
        <Col span={8}>
          <ChartHorizontalBarSummary
            field="operatingSystem"
            centerLabel="Operating System (Top 10)"
            onItemClicked={(item) =>
              setInput({ ...input, operatingSystem: [item.x] })
            }
            onClick={handleChartClicked}
          />
        </Col>
      </Row>
      <ChartModal
        open={showDetailedChart}
        field={selectedField}
        centerLabel={selectedLabel}
        onClose={() => setShowDetailedChart(false)}
      />
    </>
  );
};

export default ChartLayout;
